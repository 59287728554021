// extracted by mini-css-extract-plugin
export var WelcomeOverlay = "T_c7";
export var aniBgLoading = "T_f";
export var aniSpin = "T_d";
export var bg = "T_V";
export var body = "T_bj";
export var bodyText = "T_dc";
export var close = "T_c9";
export var closeButton = "T_dd";
export var grid = "T_C";
export var head = "T_db";
export var header = "T_D";
export var header__inner = "T_bg";
export var heading = "T_F";
export var inner = "T_h";
export var scroll = "T_c8";