// extracted by mini-css-extract-plugin
export var Footer = "P_cy";
export var aniBgLoading = "P_f";
export var aniSpin = "P_d";
export var column = "P_br";
export var columns = "P_cC";
export var heading = "P_F";
export var link = "P_bs";
export var main = "P_c";
export var nav = "P_bq";
export var newsletter = "P_cD";
export var newsletter__heading = "P_cF";
export var scrollTop = "P_cz";
export var scrollTop__btn = "P_cB";